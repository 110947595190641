/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    radar: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.634 1.135A7 7 0 0115 8a.5.5 0 01-1 0 6 6 0 10-6.5 5.98v-1.005A5 5 0 1113 8a.5.5 0 01-1 0 4 4 0 10-4.5 3.969v-1.011A2.999 2.999 0 1111 8a.5.5 0 01-1 0 2 2 0 10-2.5 1.936v-1.07a1 1 0 111 0V15.5a.5.5 0 01-1 0v-.518a7 7 0 01-.866-13.847"/>',
    },
});
